<template>
  <div class="ai">
    <div class="left">
      <div class="r1-c1">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">设备报警信息</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <!--  -->
              <div id="echarts-r1c1"></div>
              <!--  -->
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="r1-c2">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">智眸AI告警</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-1 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <!--  -->
              <div class="r1-c2-wrap">
                <div class="menu">
                  <div
                    v-for="(item, index) in menuList"
                    :key="index"
                    class="item"
                    :class="[index == currentCurveMenuIndex ? 'active' : '']"
                    @click="onToggleCurrentCurveMenuIndex(index)"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div id="echarts-r1c2"></div>
              </div>
              <!--  -->
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="r1-c3">
        <div class="border-wrap">
          <div class="border-top flex">
            <div class="border-top-left">
              <img src="../assets/border/border-left-top.png" />
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-title">
              <div class="border-name">智眸AI抓拍记录</div>
            </div>
            <div class="border-top grow">
              <img src="../assets/border/border-top.png" />
            </div>
            <div class="border-top-right">
              <img src="../assets/border/border-right-top.png" />
            </div>
          </div>
          <div class="border-body row-height-2 flex">
            <div class="border-left">
              <img src="../assets/border/border-left.png" />
            </div>
            <div class="border-body-middle grow">
              <!--  -->
              <div class="captureList">
                <!-- <div class="menu" v-if="captureData.length">
                  <div
                    class="item"
                    v-for="(item, index) in captureData"
                    :key="index"
                    :class="[index == captureListMenuIndex ? 'active' : '']"
                    @click="toggleCaptureListMenuIndex(index)"
                  >
                    {{ item.name }}
                  </div>
                </div> -->
                <div class="capture" v-if="currentCaptureList">
                  <img
                    class="big"
                    :src="`${currentCapture}`"
                    @click="lookCapture = true"
                  />
                  <div class="list">
                    <img
                      class="item"
                      v-for="(item, index) in currentCaptureList.picList"
                      :key="index"
                      :src="`${item}`"
                      @click="currentCaptureIndex = index"
                      :class="[currentCaptureIndex == index ? 'active' : '']"
                    />
                  </div>
                </div>
                <transition name="fade" v-if="currentCaptureList">
                  <div class="look" v-show="lookCapture">
                    <transition name="message">
                      <div class="bg" v-show="lookCapture">
                        <span class="close" @click="lookCapture = false"
                          >✕</span
                        >
                        <div class="main">
                          <img class="image" :src="`${currentCapture}`" />
                          <img class="border" src="../assets/ai/border.png" />
                        </div>
                        <div class="message">
                          <span class="title">异常告警</span>
                          <!-- <span>告警人员：{{ currentCapture.alarmType }}</span>
                          <span>告警地点：{{ currentCapture.alarmAddr }}</span>
                          <span>告警时间：{{ currentCapture.alarmDate }}</span> -->
                          <img class="icon" src="../assets/ai/icon.png" />
                        </div>
                      </div>
                    </transition>
                  </div>
                </transition>
              </div>
              <!--  -->
            </div>
            <div class="border-right">
              <img src="../assets/border/border-right.png" />
            </div>
          </div>
          <div class="border-bottom flex">
            <div class="border-bottom-left">
              <img src="../assets/border/border-left-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-middle">
              <img src="../assets/border/border-middle-bottom.png" />
            </div>
            <div class="border-bottom grow">
              <img src="../assets/border/border-bottom.png" />
            </div>
            <div class="border-bottom-right">
              <img src="../assets/border/border-right-bottom.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@Width: 192rem;
.row-height-1 {
  height: 335 / @Width;
}
.row-height-2 {
  height: 765 / @Width;
}

.ai {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: 680 / @Width;
    height: 100%;
  }
  .right {
    width: 1140 / @Width;
    height: 100%;
  }
  .r1-c1 {
    margin-bottom: 20 / @Width;
  }
}

.border-wrap {
  .flex {
    display: flex;
  }
  .grow {
    flex-grow: 1;
  }
  .border-top {
    .border-top-left,
    .border-top-right {
      width: 50 / @Width;
      height: 50 / @Width;
      img {
        width: 50 / @Width;
        height: 50 / @Width;
      }
    }
    .border-top {
      height: 50 / @Width;
      img {
        width: 100%;
        height: 50 / @Width;
      }
    }
    .border-title {
      width: 205 / @Width;
      height: 50 / @Width;
      background-image: url("../assets/border/border-title.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      .border-name {
        color: #00e4ff;
        font-size: 18 / @Width;
        font-weight: 700;
        text-align: center;
        margin-top: 8 / @Width;
      }
    }
  }

  .border-body {
    .border-left,
    .border-right {
      width: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 100%;
      }
    }
    .border-body-middle {
      background-image: url("../assets/border/border-body-bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }

  .border-bottom {
    .border-bottom-left,
    .border-bottom-right {
      width: 25 / @Width;
      height: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 25 / @Width;
      }
    }
    .border-bottom {
      height: 25 / @Width;
      img {
        width: 100%;
        height: 25 / @Width;
      }
    }
    .border-bottom-middle {
      width: 258 / @Width;
      height: 25 / @Width;
      img {
        width: 258 / @Width;
        height: 25 / @Width;
      }
    }
  }
}

#echarts-r1c1,
#echarts-r1c2 {
  width: 100%;
  height: 100%;
}

.r1-c2-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .menu {
    position: absolute;
    top: -30 / @Width;
    display: flex;
    .item {
      color: #00e4ff;
      height: 16 / @Width;
      line-height: 16 / @Width;
      font-size: 16 / @Width;
      padding: 0 10 / @Width;
      border-right: 1 / @Width solid #00e4ff;
    }
    .item:first-child {
      border-left: 0;
    }
    .item:last-child {
      border-right: 0;
    }
    .active {
      color: #fff;
    }
  }
}

.captureList {
  width: 100%;
  height: 100%;
  position: relative;
  .menu {
    display: flex;
    position: absolute;
    top: -30 / @Width;
    .item {
      color: #00e4ff;
      height: 16 / @Width;
      line-height: 16 / @Width;
      font-size: 16 / @Width;
      padding: 0 10 / @Width;
      border-right: 1 / @Width solid #00e4ff;
    }
    .item:first-child {
      border-left: 0;
    }
    .item:last-child {
      border-right: 0;
    }
    .active {
      color: #fff;
    }
  }
  .capture {
    width: 100;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .big {
      width: 960 / @Width;
      height: 540 / @Width;
    }
    .list {
      width: 960 / @Width;
      height: 90 / @Width;
      margin-top: 30 / @Width;
      white-space: nowrap;
      overflow: hidden;
      .item {
        width: 160 / @Width;
        height: 90 / @Width;
        margin-right: 40 / @Width;
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0);
      }
      .active {
        border: 1px solid #00e4ff;
      }
    }
  }
  .look {
    width: 1140 / @Width;
    height: 840 / @Width;
    position: absolute;
    top: -50 / @Width;
    left: -25 / @Width;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .bg {
      width: 900 / @Width;
      height: 670 / @Width;
      background-image: url("../assets/ai/bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .close {
        position: absolute;
        top: 10 / @Width;
        right: 10 / @Width;
        font-size: 16 / @Width;
        color: #00e4ff;
      }
      .main {
        width: 804 / @Width;
        height: 454 / @Width;
        position: relative;
        .image {
          position: absolute;
          width: 800 / @Width;
          height: 450 / @Width;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
        .border {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .message {
        margin-top: 20 / @Width;
        width: 800 / @Width;
        height: 60 / @Width;
        box-shadow: 0 0 5 / @Width #fff;
        padding: 0 10 / @Width;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #00e4ff;
        background: linear-gradient(
          rgba(26, 62, 184, 0.8),
          rgba(26, 62, 184, 0.1)
        );
        .title {
          font-size: 18 / @Width;
          margin-left: 10 / @Width;
        }
      }
    }
  }
}

// 背景遮罩淡入淡出
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// 登录状态动画
.message-enter-active {
  animation: message-in 0.5s;
}
.message-leave-active {
  animation: message-in 0.3s reverse;
}
@keyframes message-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes message-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

<script>
export default {
  name: "Ai",
  data() {
    return {
      alarmData: null,
      curveData: null,
      menuList: [
        {
          name: "今日",
          value: "4",
        },
        {
          name: "一周",
          value: "3",
        },
        {
          name: "一月",
          value: "2",
        },
        // {
        //   name: "一年",
        //   value: "1",
        // },
      ],
      currentCurveMenuIndex: 2,
      echarts: {
        r1c1: null,
        r1c2: null,
      },
      captureData: [],
      captureListMenuIndex: 0,
      currentCaptureIndex: 0,
      lookCapture: false,
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    currentCaptureList: function () {
      return this.captureData[this.captureListMenuIndex];
    },
    currentCapture: function () {
      if (this.currentCaptureList) {
        return this.currentCaptureList.picList[this.currentCaptureIndex];
      }
    },
  },
  watch: {
    currentProject(newVal, oldVal) {
      console.log("watch currentProject=>", newVal);
      this.onGetAlarmData(); // 获取设备报警信息数据
      this.onGetCurveData(); // 获取智眸AI报警曲线数据
      this.onGetCaptureData(); // 获取智眸AI抓拍记录数据
    },
    currentCurveMenuIndex(newVal, oldVal) {
      console.log("watch currentCurveMenuIndex=>", newVal);
      this.onGetCurveData(); // 获取智眸AI报警曲线数据
    },
    captureListMenuIndex(newVal, oldVal) {
      this.currentCaptureIndex = 0;
    },
  },
  mounted() {
    this.onGetAlarmData(); // 获取设备报警信息数据
    this.onGetCurveData(); // 获取智眸AI报警曲线数据
    this.onGetCaptureData(); // 智眸AI抓拍记录数据
  },
  methods: {
    // 获取设备报警信息饼图数据
    onGetAlarmData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/godseyeView/viewArlarm`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.alarmData = res.data.itemList;
          that.echartsR1C1Init(); // 绘制设备报警信息饼图
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 绘制设备报警信息饼图
    echartsR1C1Init: function () {
      this.echarts.r1c1 = this.$echarts
        .init(document.getElementById("echarts-r1c1"))
        .dispose();
      this.echarts.r1c1 = this.$echarts.init(
        document.getElementById("echarts-r1c1")
      );
      var listData = this.alarmData;
      var data = [];
      var legend = [];
      listData.forEach((item, index) => {
        data.push({
          name: item.alarmName,
          value: item.alarmNum,
        });
        legend.push(item.alarmName);
      });
      var option = {
        legend: {
          data: legend,
          orient: "vertical",
          bottom: 10,
          right: 10,
          textStyle: {
            color: "#00e4ff",
          },
          itemWidth: 7,
          itemHeight: 7,
          formatter: function (name) {
            var arr;
            listData.forEach((item, index) => {
              if (name == item.alarmName) {
                arr =
                  item.alarmName + item.alarmNum + "次 " + item.alarmPer + "%";
              }
            });
            return arr;
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
        },
        series: [
          {
            name: "设备报警信息",
            type: "pie",
            center: ["35%", "50%"],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            label: {
              formatter: "{d}%",
              color: "#00e4ff",
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data,
          },
        ],
      };
      this.echarts.r1c1.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r1c1.resize();
      });
    },
    // 获取智眸AI报警曲线数据
    onGetCurveData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
        timeType: this.menuList[this.currentCurveMenuIndex].value,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/godseyeView/viewArlarmStatics`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.curveData = res.data;
          that.echartsR1C2Init(); // 绘制智眸AI报警曲线
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换智眸AI报警曲线时间类型
    onToggleCurrentCurveMenuIndex: function (index) {
      this.currentCurveMenuIndex = index;
    },
    // 绘制智眸AI报警曲线
    echartsR1C2Init: function () {
      this.echarts.r1c2 = this.$echarts
        .init(document.getElementById("echarts-r1c2"))
        .dispose();
      this.echarts.r1c2 = this.$echarts.init(
        document.getElementById("echarts-r1c2")
      );
      var colorList = [
        {
          itemColor: "rgba(0,179,244,1)",
          shadowColor1: "rgba(0,179,244,0.8)",
          shadowColor2: "rgba(0,179,244,0.3)",
        },
        {
          itemColor: "rgba(0,202,149,1)",
          shadowColor1: "rgba(0,202,149,0.8)",
          shadowColor2: "rgba(0,202,149,0.3)",
        },
        {
          itemColor: "rgba(255,215,0,1)",
          shadowColor1: "rgba(255,215,0,0.8)",
          shadowColor2: "rgba(255,215,0,0.3)",
        },
        {
          itemColor: "rgba(255,69,0,1)",
          shadowColor1: "rgba(255,69,0,0.8)",
          shadowColor2: "rgba(255,69,0,0.3)",
        },
        {
          itemColor: "rgba(160,32,240,1)",
          shadowColor1: "rgba(160,32,240,0.8)",
          shadowColor2: "rgba(160,32,240,0.3)",
        },
      ];
      var series = [];
      var legend = [];
      this.curveData.series.forEach((item, index) => {
        var seriesItem = {
          name: item.name,
          type: "line",
          smooth: true, //是否平滑
          showAllSymbol: true,
          symbol: "circle",
          symbolSize: 15,
          lineStyle: {
            normal: {
              color: colorList[index].itemColor,
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 5,
              shadowOffsetX: 5,
            },
          },
          label: {
            show: true,
            position: "top",
            textStyle: {
              color: colorList[index].itemColor,
            },
          },
          itemStyle: {
            opacity: 0,
            // borderColor: "#fff",
            // borderWidth: 3,
            // shadowColor: "rgba(0, 0, 0, .3)",
            // shadowBlur: 0,
            // shadowOffsetY: 2,
            // shadowOffsetX: 2,
          },
          tooltip: {
            show: true,
          },
          areaStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: colorList[index].shadowColor2,
                  },
                  {
                    offset: 1,
                    color: "rgba(0,179,244,0)",
                  },
                ],
                false
              ),
              shadowColor: colorList[index].shadowColor1,
              shadowBlur: 20,
            },
          },
          data: this.curveData.series[index].data,
        };
        series.push(seriesItem);
        legend.push(item.name);
      });
      var option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(15,16,66,.8)",
          textStyle: {
            color: "#00e4ff",
          },
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0, 255, 233,0)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255, 255, 255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 255, 233,0)",
                  },
                ],
                global: false,
              },
            },
          },
        },
        legend: {
          data: legend,
          top: 10,
          right: "5%",
          textStyle: {
            color: "#00e4ff",
          },
        },
        grid: {
          top: "15%",
          left: "5%",
          right: "5%",
          bottom: "15%",
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            axisLabel: {
              color: "#00e4ff",
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: this.curveData.time,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisLine: {
              show: false, // Y 轴的竖线
            },
            axisLabel: {
              show: false, // Y 轴的参考单位
              margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: series,
      };
      this.echarts.r1c2.setOption(option);
      window.addEventListener("resize", () => {
        this.echarts.r1c2.resize();
      });
    },
    // 获取智眸AI抓拍记录数据
    onGetCaptureData: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/godseyeView/captureList`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.captureData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换智眸AI抓拍记录菜单
    toggleCaptureListMenuIndex: function (index) {
      this.captureListMenuIndex = index;
    },
  },
};
</script>